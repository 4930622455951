<template>
  <div class="wrapper">
    <div
      v-for="(item, index) in formats"
      :key="index"
      :class="{ format: true, active: index === selectedFormatIndex }"
      :style="formatStyle(item.width, item.height)"
      @click="$emit('change', index)"
    >
      {{ item.width }}:{{ item.height }}
    </div>
  </div>
</template>

<script>
const SIZE = 70;
export default {
  name: "FormatSelector",
  props: ["selectedFormatIndex", "formats"],
  methods: {
    formatStyle: function (width, height) {
      const ratio = width / height;
      let styles = {};
      let w, h;
      if (width >= height) {
        w = SIZE;
        h = SIZE / ratio;
      } else {
        h = SIZE;
        w = SIZE * ratio;
      }
      styles.width = `${w}px`;
      styles.height = `${h}px`;
      return styles;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.format {
  margin-bottom: s(2);
  cursor: pointer;
  display: inline-block;
  // max-width: 50px;
  // max-height: 50px;
  background: $white;
  border: 3px solid darken($grey-dark, 10);
  font-size: 14px;
  color: darken($grey-dark, 50);
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    border-color: $black;
    color: $black;
  }
}
</style>
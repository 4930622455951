<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
export default {
  name: "ImagePreview",
  data() {
    return {
      image: null,
      canvas: null,
      context: null,
      width: 0,
      height: 0,
      image_width: 0,
      image_height: 0,
      image_ratio: 0,
      maxHeight: 180,
    };
  },
  props: ["imageSource", "aspectRatio", "keepImageProportions"],
  methods: {
    render: function () {
      this.context.clearRect(0, 0, this.width, this.height);

      if (this.keepImageProportions) {
        var scale = Math.max(
          this.canvas.width / this.image.width,
          this.canvas.height / this.image.height
        );
        // get the top left position of the image
        var x = this.canvas.width / 2 - (this.image.width / 2) * scale;
        var y = this.canvas.height / 2 - (this.image.height / 2) * scale;
        this.context.drawImage(
          this.image,
          x,
          y,
          this.image.width * scale,
          this.image.height * scale
        );
      }

      if (!this.keepImageProportions) {
        this.context.drawImage(this.image, 0, 0, this.width, this.height);
      }
    },
    imageLoaded: function () {
      this.image_width = this.image.width;
      this.image_height = this.image.height;
      this.image_ratio = this.image_width / this.image_height;
      this.width = this.canvas.width = this.maxHeight * this.aspectRatio;
      this.height = this.canvas.height = this.maxHeight;
      this.render();
    },
  },
  watch: {
    imageSource: function (newValue) {
      this.image.src = this.imageSource;
      this.render();
    },
    aspectRatio: function (newValue) {
      this.imageLoaded();
      this.render();
    },
    keepImageProportions: function (newValue) {
      this.render();
    },
  },
  mounted() {
    this.canvas = this.$refs["canvas"];
    this.context = this.canvas.getContext("2d");
    this.image = new Image();
    this.image.addEventListener("load", this.imageLoaded);
    this.image.src = this.imageSource;
  },
};
</script>

<style>
canvas {
}
</style>
<template>
  <div>
    <div class="slider" ref="parent">
      <div class="track" ref="track"></div>
      <div
        class="default"
        v-if="defaultSet"
        :style="{ left: `${pixelFromValue(_default)}px` }"
      ></div>
      <div class="knob" ref="knob"></div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "",
  props: ["modelValue", "min", "max", "step", "default"],
  data() {
    return {
      content: this.value,
      parent: null,
      knob: null,
      defaultSet: false,
      _default: null,
      _min: 0,
      _max: 50,
      _step: 5,
      snapPixelValue: 0,
      track: null,
      value: 0,
      position: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    mousedown: function (event) {
      event.preventDefault();
      event.stopPropagation();
      window.addEventListener("mousemove", this.mousemove);
      window.addEventListener("mouseup", this.mouseup);
      window.addEventListener("touchmove", this.mousemove);
      window.addEventListener("touchend", this.mouseup);
    },
    mousemove: function (event) {
      let x = null;
      if (event.type === "touchmove") {
        event.preventDefault();
        event.stopPropagation();
        x = event.changedTouches[0].clientX;
      } else {
        x = event.x;
      }
      const percentage = this.clamp(
        (x - this.position.x) / this.position.width
      );
      this.setPositionFromPercentage(percentage);

      this.update();
    },
    mouseup: function (event) {
      window.removeEventListener("mousemove", this.mousemove);
      window.removeEventListener("mouseup", this.mouseup);

      window.removeEventListener("touchmove", this.mousemove);
      window.removeEventListener("touchend", this.mouseup);
    },
    pixelFromPercentage: function (percentage) {
      let x = percentage * this.position.width;
      x = gsap.utils.snap(this.snapPixelValue, x);
      return x;
    },
    pixelFromValue: function (value) {
      let pixels =
        gsap.utils.mapRange(
          this._min,
          this._max,
          0,
          this.position.width,
          value
        ) +
        this.knobSize / 2;

      return pixels;
    },
    setPositionFromPercentage: function (percentage) {
      this.value = this.snapValue(
        gsap.utils.mapRange(0, 1, this._min, this._max, percentage)
      );
      //   gsap.set(this.knob, {
      //     x: this.pixelFromPercentage(percentage),
      //   });
      let newPercentage = gsap.utils.mapRange(
        this._min,
        this._max,
        0,
        1,
        this.value
      );
      //   console.log(percentage);
      //   console.log("updated", newPercentage);
      gsap.set(this.knob, {
        x: this.pixelFromPercentage(newPercentage),
      });
      this.content = this.value;
    },
    setPositionFromValue: function (value) {
      let percentage = this.clamp(
        gsap.utils.mapRange(this._min, this._max, 0, 1, value)
      );
      this.setPositionFromPercentage(percentage);
      this.update();
    },
    trackClick: function (event) {
      this.mousemove(event);
    },
    resize: function () {
      this.knobSize = this.knob.offsetWidth;
      this.position = this.track.getBoundingClientRect();
      this.position.width = this.position.width - this.knobSize;
      this.snapPixelValue =
        (1 / ((this._max - this._min) / this.step)) * this.position.width;
    },

    snapValue: function (value) {
      let val = gsap.utils.snap(this.step, this.clampValue(value));

      return val;
    },
    update: function () {
      this.$emit("update:modelValue", this.content);
    },
  },
  computed: {
    minSnapValue: function () {
      let minValue;

      if (this._min % this._step === 0) {
        minValue = this._min;
      } else {
        let diff = Math.floor(this._min % this._step);
        minValue = this._min - diff;
      }
      return minValue;
    },
    maxSnapValue: function () {
      let maxValue;

      if (this._max % this._step === 0) {
        maxValue = this._max;
      } else {
        let diff = Math.floor(this._max % this._step);
        maxValue = this._max - diff;
      }
      return maxValue;
    },
  },
  watch: {
    modelValue: function (newValue) {
      this.setPositionFromValue(newValue);
    },
  },
  mounted() {
    this.clamp = gsap.utils.clamp(0, 1);
    this.parent = this.$refs.parent;
    this.knob = this.$refs.knob;
    this.track = this.$refs.track;

    this._min = Number(this.min) || 0;
    this._max = Number(this.max) || 100;
    this._step = Number(this.step) || 1;
    this.defaultSet = true;
    this._default = Number(this.default);

    this.clampValue = gsap.utils.clamp(this.minSnapValue, this.maxSnapValue);

    this.resize();
    this.setPositionFromValue(this.modelValue);
    window.addEventListener("resize", this.resize);

    this.knob.addEventListener("mousedown", this.mousedown);
    this.knob.addEventListener("touchstart", this.mousedown);

    this.parent.addEventListener("click", this.trackClick);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/global.scss";

.slider {
  height: 40px;
  position: relative;

  .track {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    background: $black;
    // opacity: 0.25;
    border-radius: 2px;
  }

  .knob {
    position: absolute;
    width: 24px;
    height: 24px;
    border: 2px solid $black;
    background: $white;
    border-radius: 20px;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;

    &:hover {
      border-color: $blue;
    }

    &:active {
      border-color: $blue;
      background: $blue;
    }
  }
}
</style>
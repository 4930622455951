<template>
  <div class="wrapper" @click="update">
    <div :class="{ checkbox: true, active: checked }">
      <div class="inner"></div>
    </div>
    {{ label }}
  </div>
</template>

<script>
const SIZE = 70;
export default {
  name: "Checkbox",
  props: ["checked", "label"],
  methods: {
    update: function () {
      this.value = !this.value;
      this.$emit("change", this.value);
    },
  },
  mounted() {
    this.value = this.checked;
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkbox {
  width: 24px;
  height: 24px;
  border: 2px solid $black;
  margin-right: s(1);
  border-radius: s(0.5);

  .inner {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: s(0.25);
  }
  &.active {
    .inner {
      background: $black;
    }
  }
}
</style>
<template>
  <div class="sidebar">
    <h1>
      <span class="bold">andel </span>
      <span class="light">energi</span>
    </h1>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<style lang="scss" >
.sidebar {
  width: $sidebar_width;
  height: 100vh;
  background: $white;
  padding: $sidebar_margin;
  padding-bottom: 0;

  overflow-y: auto;
  h1 {
    margin-bottom: $sidebar_margin;
    font-weight: normal;
    .bold {
      font-weight: 600;
    }
    .light {
      font-weight: 300;
    }
  }
  section {
    margin-bottom: $sidebar_margin;

    &.export {
      margin-left: -$sidebar_margin;
      margin-right: -$sidebar_margin;
      padding-top: $sidebar_margin / 2;
      padding-bottom: $sidebar_margin / 2;
      padding-left: $sidebar_margin;
      padding-right: $sidebar_margin;
      margin-bottom: 0px;
      position: sticky;
      bottom: 0px;
      background: $white;
      border-top: s(0.5) solid $grey-light;
    }
  }

  .preview {
    margin-top: s(1);
    height: 180px;
    position: relative;

    canvas {
      display: block;
    }
    // img {
    //   object-position: top left;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   object-fit: contain;
    // }
  }

  .input {
    display: block;
    margin-bottom: s(3);

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      display: flex;
      justify-content: space-between;
      margin-bottom: s(0.5);
    }

    input {
      width: 100%;

      &[type="checkbox"] {
        // display: none;
        width: auto;
      }
    }
  }
  .control-group {
    margin-bottom: 0px;

    &:last-of-type {
      margin-bottom: $sidebar_margin;
    }
  }
}
</style>
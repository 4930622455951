const Palette1 = {
  id: "Palette1",
  colors: [
    {
      cmyk: [0, 0, 0, 0],
      rgb: { r: 255, g: 255, b: 255 }
    },
    {
      cmyk: [0, 20, 10, 0],
      rgb: { r: 255, g: 222, b: 215 }
    },
    {
      cmyk: [0, 30, 20, 0],
      rgb: { r: 252, g: 201, b: 191 }
    },
    {
      cmyk: [0, 45, 40, 0],
      rgb: { r: 255, g: 176, b: 160 }
    },
    {
      cmyk: [0, 65, 60, 0],
      rgb: { r: 255, g: 153, b: 130 }
    },
    {
      cmyk: [25, 50, 20, 5],
      rgb: { r: 191, g: 140, b: 161 }
    },
    {
      cmyk: [55, 50, 0, 0],
      rgb: { r: 128, g: 130, b: 191 }
    },
    {
      cmyk: [80, 40, 0, 0],
      rgb: { r: 64, g: 120, b: 219 }
    },
    {
      cmyk: [100, 45, 0, 0],
      rgb: { r: 0, g: 107, b: 250 }
    },
    {
      cmyk: [80, 36, 0, 0],
      rgb: { r: 69, g: 125, b: 219 }
    },
    {
      cmyk: [44, 21, 0, 0],
      rgb: { r: 125, g: 179, b: 250 }
    },
    {
      cmyk: [24, 11, 0, 0],
      rgb: { r: 187, g: 214, b: 250 }
    },
    {
      cmyk: [0, 0, 0, 0],
      rgb: { r: 255, g: 255, b: 255 }
    }
  ]
};

export default Palette1;

<template>
  <button :size="size"><slot /></button>
</template>

<script>
export default {
  name: "PillButton",
  props: ["size"],
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  cursor: pointer;
  width: 100%;
  //height: 75px;
  padding: 0.8em 1em;
  border-radius: 11px;
  font-size: 1em;
  background: $blue;
  color: $white;

  &[size="small"] {
    font-size: 1em;
    width: auto;
    display: inline-block;
  }
}
</style>
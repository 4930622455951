const Utils = {
  readImage: async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      //   const image = new Image();
      reader.onload = () => {
        // image.src = reader.result;
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },
  cssColor: function(input) {
    return `rgb(${input.r},${input.g},${input.b})`;
  }
};

export default Utils;

<template>
  <div class="control-group" ref="group" :class="{ active: isOpen }">
    <header @click="toggle">
      <h2>{{ title }}</h2>
      <div class="icon">
        <svg
          ref="icon-svg"
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1L5 7L1 1"
            stroke="#303030"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </header>
    <div class="control-content" ref="content">
      <slot />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "ControlGroup",
  props: ["title", "open"],
  data() {
    return {
      group: null,
      icon: null,
      content: null,
      headerHeight: 50,
      contentHeight: 0,
      totalHeight: 0,
      isOpen: false,
    };
  },
  methods: {
    hideContent: function (instant) {
      const duration = instant ? 0 : 0.3;
      const ease = "power2.out";
      gsap.to(this.group, {
        duration,
        height: this.headerHeight,
        ease,
      });

      gsap.to(this.icon, {
        duration,
        ease,
        rotationX: 0,
      });
    },
    showContent: function (instant) {
      const duration = instant ? 0 : 0.3;
      const ease = "power2.out";
      gsap.to(this.group, {
        duration,
        ease,
        height: this.totalHeight,
        onComplete: () => {
          this.group.style.height = "auto";
        },
      });

      gsap.to(this.icon, {
        duration,
        ease,
        rotationX: 180,
      });
    },
    toggle: function () {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.showContent();
      } else {
        this.hideContent();
      }
    },
    resize: function () {
      this.group.style.height = "auto";
      this.totalHeight = this.group.offsetHeight;
      if (!this.isOpen) {
        this.hideContent(true);
      }
    },
  },
  watch: {
    open: function (newValue) {
      this.isOpen = this.open;
    },
  },
  mounted() {
    this.isOpen = this.open || false;

    this.group = this.$refs["group"];
    this.content = this.$refs["content"];
    this.icon = this.$refs["icon-svg"];

    window.addEventListener("resize", this.resize);

    //
    // console.log(Date.now());
    // this.$nextTick(() => {
    //   console.log(Date.now());
    // });
    window.addEventListener("load", () => {
      this.resize();
      if (this.isOpen) {
        this.showContent(true);
      } else {
        this.hideContent(true);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.control-group {
  overflow: hidden;
  height: 50px;
  margin-left: (-$sidebar_margin);
  margin-right: (-$sidebar_margin);
  margin-bottom: s(0.5);
  padding: 0 $sidebar_margin;
  // border-top: 1px solid $white;
  // transition: border-top-color 0.2s ease-out;

  header {
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;

    .icon {
      height: 50px;
      //width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      perspective-origin: center;
      perspective: 700;
    }
  }

  .control-content {
    padding-top: $sidebar_margin / 2;
    padding-bottom: $sidebar_margin / 2;
  }
  &:hover {
    background: $grey-lightest;
  }

  &.active {
    background: $grey-light;
    // border-top-color: darken($grey-dark, 10);
  }
}
</style>
const Palette2 = {
  id: "Palette2",
  colors: [
    {
      cmyk: [0, 0, 0, 0],
      rgb: { r: 255, g: 255, b: 255 }
    },    {
      cmyk: [0, 0, 0, 0],
      rgb: { r: 191, g: 200, b: 216 }
    },
    {
      cmyk: [0, 20, 10, 0],
      rgb: { r: 131, g: 160, b: 192 }
    },
    {
      cmyk: [0, 30, 20, 0],
      rgb: { r: 72, g: 110, b: 147 }
    },
    {
      cmyk: [0, 45, 40, 0],
      rgb: { r: 13, g: 51, b: 113 }
    },
    {
      cmyk: [0, 65, 60, 0],
      rgb: { r: 10, g: 65, b: 147 }
    },
    {
      cmyk: [25, 50, 20, 5],
      rgb: { r: 7, g: 79, b: 181 }
    },
    {
      cmyk: [55, 50, 0, 0],
      rgb: { r: 3, g: 93, b: 215 }
    },
    {
      cmyk: [100, 45, 0, 0],
      rgb: { r: 0, g: 107, b: 250 }
    },
    {
      cmyk: [80, 36, 0, 0],
      rgb: { r: 69, g: 125, b: 219 }
    },
    {
      cmyk: [44, 21, 0, 0],
      rgb: { r: 125, g: 179, b: 250 }
    },
    {
      cmyk: [24, 11, 0, 0],
      rgb: { r: 187, g: 214, b: 250 }
    },
    {
      cmyk: [0, 0, 0, 0],
      rgb: { r: 255, g: 255, b: 255 }
    }
  ]
};

export default Palette2;

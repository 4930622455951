<template>
  <div class="wrapper">
    <div
      v-for="(item, index) in palettes"
      :key="index"
      :class="{ palette: true, active: index === selectedPaletteIndex }"
      @click="paletteChange(index)"
    >
      <div class="swatches">
        <div
          class="swatch"
          v-for="(swatch, swatchIndex) in item.colors"
          :key="swatchIndex"
          :style="{ background: cssColor(swatch.rgb) }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "../components/Utils.js";

const { cssColor } = Utils;

export default {
  name: "PaletteSelector",
  props: ["selectedPaletteIndex", "palettes"],
  methods: {
    cssColor,
    paletteChange: function (index) {
      const currentPalette = this.palettes[index];
      const bottomColor = currentPalette.colors[4].rgb;
      const topColor = currentPalette.colors[7].rgb;
      const canvas = document.createElement("canvas");
      canvas.width = 16;
      canvas.height = 16;
      const c = canvas.getContext("2d");
      c.fillStyle = cssColor(topColor);
      c.fillRect(0, 0, 16, 8);
      c.fillStyle = cssColor(bottomColor);
      c.fillRect(0, 8, 16, 8);

      const icon = document.querySelector('link[rel="icon"]');
      icon.href = canvas.toDataURL("image/png");
      this.$emit("change", index);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: auto;
  display: flex;
  justify-content: flex-start;
  .palette {
    width: 40px;
    //width: auto;
    margin-bottom: s(3);
    cursor: pointer;
    transition: box-shadow 0.2s;
    margin-right: 20px;
    &.active {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
    .swatches {
      display: flex;
      flex-flow: column-reverse;
      .swatch {
        width: 40px;
        height: 40px;
        display: none;
        &:nth-child(5),
        &:nth-child(8) {
          display: block;
        }
      }
    }
  }
}
</style>
<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss">
.app {
  margin: 0;
}
</style>

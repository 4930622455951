<template>
  <div ref="output" class="output-canvas">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import gsap from "gsap";
const longestSidePercent = 0.8;

const DOWNLOAD_LONGEST_SIDE = 4096;
const svgNS = "http://www.w3.org/2000/svg";

export default {
  name: "OutputCanvas",
  props: [
    "aspectRatio",
    "image",
    "contrast",
    "brightness",
    "gamma",
    "columns",
    "rows",
    "keepImageProportions",
    "palette",
    "debug",
  ],
  data() {
    return {
      pdfDoc: null,
      pdfStream: null,
      imageWidth: 0,
      imageHeight: 0,
      imageRatio: 0,
      stepWidth: 0,
      stepHeight: 0,
      width: 0,
      height: 0,
      element: null,
      outputCanvas: null,
      outputContext: null,
      sourceCanvas: null,
      sourceContext: null,
      colorCanvas: null,
      colorContext: null,
      downloadCanvas: null,
      downloadContext: null,
      svgElement: null,
      imageElement: null,
      parent: null,
      imageSource: null,
      animationRequest: null,
    };
  },
  methods: {
    update: function () {
      const parentRatio = this.parent.offsetWidth / this.parent.offsetHeight;
      let width, height;

      if (this.aspectRatio <= parentRatio) {
        height = longestSidePercent * window.innerHeight;
        width = height * this.aspectRatio;
      } else {
        width = longestSidePercent * this.parent.offsetWidth;
        height = width / this.aspectRatio;
      }
      this.element.style.width = `${width}px`;
      this.element.style.height = `${height}px`;

      this.width = this.outputCanvas.width = this.element.offsetWidth;
      this.height = this.outputCanvas.height = this.element.offsetHeight;

      this.stepWidth = this.width / this.columns;
      this.stepHeight = this.height / this.rows;

      this.svgElement.setAttributeNS(
        null,
        "viewBox",
        `0 0 ${this.width} ${this.height}`
      );
      this.svgElement.setAttributeNS(null, "width", `${this.width}`);
      this.svgElement.setAttributeNS(null, "height", `${this.height}`);

      const ratio = this.aspectRatio;
      let downloadWidth = 0;
      let downloadHeight = 0;
      if (this.width >= this.height) {
        downloadWidth = DOWNLOAD_LONGEST_SIDE;
        downloadHeight = Math.floor(downloadWidth / ratio);
      } else {
        downloadHeight = DOWNLOAD_LONGEST_SIDE;
        downloadWidth = Math.floor(downloadHeight * ratio);
      }
      this.downloadCanvas.width = downloadWidth;
      this.downloadCanvas.height = downloadHeight;

      this.colorCanvas.width = this.columns;
      this.colorCanvas.height = this.rows;
      this.sourceCanvas.width = this.columns;
      this.sourceCanvas.height = this.rows;
      this.sourceContext.clearRect(0, 0, this.columns, this.rows);

      if (this.imageSource) {
        if (this.keepImageProportions) {
          var scale = Math.max(
            this.sourceCanvas.width / this.imageElement.width,
            this.sourceCanvas.height / this.imageElement.height
          );
          // get the top left position of the image
          var x =
            this.sourceCanvas.width / 2 - (this.imageElement.width / 2) * scale;
          var y =
            this.sourceCanvas.height / 2 -
            (this.imageElement.height / 2) * scale;
          this.sourceContext.drawImage(
            this.imageElement,
            x,
            y,
            this.imageElement.width * scale,
            this.imageElement.height * scale
          );
        } else {
          this.sourceContext.drawImage(
            this.imageElement,
            0,
            0,
            this.columns,
            this.rows
          );
        }
      }

      this.requestRender();
    },
    distance: function (a, b) {
      return Math.sqrt(
        Math.pow(a.r - b.r, 2) + Math.pow(a.g - b.g, 2) + Math.pow(a.b - b.b, 2)
      );
    },

    nearestColor: function (inputColorRGB) {
      var lowest = Number.POSITIVE_INFINITY;
      var tmp;
      let index = 0;
      this.palette.colors.forEach((el, i) => {
        tmp = this.distance(inputColorRGB, el.rgb);
        if (tmp < lowest) {
          lowest = tmp;
          index = i;
        }
      });
      return this.palette.colors[index].rgb;
    },
    resize: function () {
      this.update();
    },
    requestRender: function () {
      if (this.animationRequest) {
        cancelAnimationFrame(this.animationRequest);
      }
      this.animationRequest = requestAnimationFrame(this.render);
    },
    render: function (destination) {
      const {
        colorContext,
        colorCanvas,
        sourceContext,
        sourceCanvas,
        outputContext,
      } = this;

      sourceContext.imageSmoothingEnabled = false;
      colorContext.imageSmoothingEnabled = false;

      colorContext.filter = `contrast(${this.contrast}) brightness(${this.brightness})`;

      colorContext.drawImage(this.sourceCanvas, 0, 0, this.columns, this.rows);

      if (this.imageElement.src) {
        // Process colors
        // Calculate greyscale value
        const imageData = colorContext.getImageData(
          0,
          0,
          this.columns,
          this.rows
        ).data;

        const imageDataLength = imageData.length;

        let greyColors = [];
        let minGreyValue = 255;
        let maxGreyValue = 0;

        for (let i = 0; i < imageDataLength; i += 4) {
          const greyValue = Math.floor(
            (parseInt(imageData[i + 0] * 0.299) +
              parseInt(imageData[i + 1] * 0.587) +
              parseInt(imageData[i + 2] * 0.114)) *
              this.gamma
          );

          if (greyValue > maxGreyValue) {
            maxGreyValue = greyValue;
          }
          if (greyValue < minGreyValue) {
            minGreyValue = greyValue;
          }
          greyColors.push(greyValue);
        }

        minGreyValue = minGreyValue < 0 ? 0 : minGreyValue;
        maxGreyValue = maxGreyValue > 255 ? 255 : maxGreyValue;
        const mapper = gsap.utils.pipe(
          gsap.utils.mapRange(
            minGreyValue,
            maxGreyValue,
            0,
            this.numColors - 1
          ),
          gsap.utils.clamp(0, this.numColors - 1)
        );

        const greyValueToColorIndex = (value) => {
          const newValue = Math.floor(mapper(value));
          return newValue;
        };

        let greyColorsLength = greyColors.length;

        let context = outputContext;
        let stepWidth = this.stepWidth;
        let stepHeight = this.stepHeight;

        if (destination === "download") {
          context = this.downloadContext;
          stepWidth = this.downloadCanvas.width / this.columns;
          stepHeight = this.downloadCanvas.height / this.rows;
        }

        if (destination != "svg" && destination != "pdf") {
          destination = "canvas";
        }
        let minIndex = this.numColors;
        let maxIndex = 0;
        for (let i = 0; i < greyColorsLength; i++) {
          const x = i % this.columns;
          const y = Math.floor(i / this.columns);

          const colorIndex = greyValueToColorIndex(greyColors[i]);

          if (colorIndex > maxIndex) {
            maxIndex = colorIndex;
          }
          if (colorIndex < minIndex) {
            minIndex = colorIndex;
          }
          const color = this.palette.colors[colorIndex].rgb;
          const cmyk = this.palette.colors[colorIndex].cmyk;

          const isLastColumn = x === this.columns - 1;
          const isLastRow = y === this.rows - 1;

          let stepWidthAdjusted = stepWidth + 1;
          let stepHeightAdjusted = stepHeight + 1;

          if (isLastColumn) {
            stepWidthAdjusted = stepWidth;
          }
          if (isLastRow) {
            stepHeightAdjusted = stepHeight;
          }
          if (destination === "svg") {
            const rect = document.createElementNS(svgNS, "rect");
            rect.setAttribute("x", x * stepWidth);
            rect.setAttribute("y", y * stepHeight);
            rect.setAttribute("width", stepWidthAdjusted);
            rect.setAttribute("height", stepHeightAdjusted);
            rect.setAttribute("fill", `rgb(${color.r},${color.g},${color.b})`);
            this.svgElement.appendChild(rect);
          }
          if (destination === "pdf") {
            this.pdfDoc
              .rect(
                x * stepWidth,
                y * stepHeight,
                stepWidthAdjusted,
                stepHeightAdjusted
              )
              .fill(cmyk);
          }
          if (destination == "canvas") {
            context.fillStyle = `rgb(${color.r},${color.g},${color.b})`;
            context.fillRect(
              x * stepWidth,
              y * stepHeight,
              stepWidthAdjusted,
              stepHeightAdjusted
            );

            if (this.debug) {
              const fontSize = Math.min(stepWidth, stepHeight) * 0.75;
              context.font = `${fontSize}px Andel`;
              context.textAlign = "center";
              context.textBaseLine = "middle";

              context.fillStyle = "black";
              context.fillText(
                colorIndex,
                x * stepWidth + stepWidth * 0.5 + 1,
                y * stepHeight + stepHeight * 0.5 + fontSize * 0.35 + 1
              );
              context.fillStyle = "white";
              context.fillText(
                colorIndex,
                x * stepWidth + stepWidth * 0.5,
                y * stepHeight + stepHeight * 0.5 + fontSize * 0.35
              );
            }
          }
        }
      }
    },
    imageLoaded: function () {
      this.imageWidth = this.imageElement.width;
      this.imageHeight = this.imageElement.height;
      this.imageRatio = this.imageWidth / this.imageHeight;

      this.update();
    },
    exportAs: function (type) {
      if (type === "PNG") {
        const link = document.createElement("a");
        this.render("download");
        const img = this.downloadCanvas.toDataURL("image/png");
        link.href = img;
        link.download = "andel.png";
        link.click();
      }

      if (type === "SVG") {
        this.render("svg");
        const svgBlob = new Blob([this.svgElement.outerHTML], {
          type: "image/svg+xml",
        });
        const url = URL.createObjectURL(svgBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "andel.svg";
        a.click();
        window.URL.revokeObjectURL(url);
      }

      if (type === "PDF") {
        this.pdfDoc = new PDFDocument({
          size: [this.width, this.height],
          margin: 0,
        });

        this.pdfStream = this.pdfDoc.pipe(blobStream());

        this.render("pdf");

        this.pdfDoc.end();

        this.pdfStream.on("finish", () => {
          let blob = this.pdfStream.toBlob("application/pdf");
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "andel.pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
    },
  },
  computed: {
    numColors: function () {
      return this.palette.colors.length;
    },
  },
  watch: {
    aspectRatio: function (newValue) {
      this.update();
    },
    image: function (newImage) {
      this.imageSource = newImage;
      this.imageElement.src = this.imageSource;
    },
    keepImageProportions: function () {
      this.update();
    },
    contrast: function (newValue) {
      this.requestRender();
    },
    brightness: function (newValue) {
      this.requestRender();
    },
    gamma: function (newValue) {
      this.requestRender();
    },
    columns: function (newValue) {
      this.update();
    },
    rows: function (newValue) {
      this.update();
    },
    palette: function (newValue) {
      this.requestRender();
    },
  },
  mounted() {
    this.element = this.$refs["output"];
    this.element.style.position = "relative";
    this.outputCanvas = this.$refs["canvas"];
    this.outputContext = this.outputCanvas.getContext("2d");
    this.outputContext.imageSmoothingEnabled = false;

    this.sourceCanvas = document.createElement("canvas");
    this.sourceCanvas.width = this.columns;
    this.sourceCanvas.height = this.rows;
    this.sourceContext = this.sourceCanvas.getContext("2d");
    this.sourceContext.imageSmoothingEnabled = false;

    this.colorCanvas = document.createElement("canvas");
    this.colorCanvas.width = this.columns;
    this.colorCanvas.height = this.rows;
    this.colorContext = this.colorCanvas.getContext("2d");
    this.colorContext.imageSmoothingEnabled = false;

    this.downloadCanvas = document.createElement("canvas");
    this.downloadCanvas.width = this.columns;
    this.downloadCanvas.height = this.rows;
    this.downloadContext = this.downloadCanvas.getContext("2d");
    this.downloadContext.imageSmoothingEnabled = false;

    this.svgElement = document.createElementNS(svgNS, "svg");

    this.imageElement = new Image();
    this.imageElement.addEventListener("load", this.imageLoaded);
    this.parent = this.element.parentNode;

    window.addEventListener("resize", this.resize);
    window.addEventListener("load", this.resize);
  },
};
</script>

<style lang="scss" scoped>
.output-canvas {
  position: absolute;
  background: white;
  // min-width: 100px;
  width: 80%;
  height: 0;
  overflow: hidden;
  canvas {
    width: 100%;
    height: 100%;
  }
  // img {
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   object-position: center center;
  // }
}
</style>
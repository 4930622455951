<template>
  <div>
    <input type="file" accept="image/*" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div {
  background: red;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 5;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
<template>
  <div class="link"><slot /></div>
</template>

<script>
export default {
  name: "TextLink",
};
</script>

<style lang="scss" scoped>
.link {
  display: inline-block;
  color: $black;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
</style>
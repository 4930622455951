<template>
  <div class="generator-view">
    <div class="export-menu" ref="export-menu" v-show="showExportMenu">
      <ul>
        <li>
          <text-link @click="exportAs('SVG')"
            >SVG<span class="secondary">rgb</span></text-link
          >
        </li>
        <li>
          <text-link @click="exportAs('PNG')"
            >PNG<span class="secondary">rgb</span></text-link
          >
        </li>
        <li>
          <text-link @click="exportAs('PDF')"
            >PDF<span class="secondary">cmyk</span></text-link
          >
        </li>
      </ul>
    </div>
    <div class="drag-message" v-show="showDragMessage">
      <input ref="drag-input" type="file" accept="image/*" />
    </div>
    <div class="drag-message-layout" ref="drag-message-layout">
      <h1 ref="drag-title">Drop image here</h1>
    </div>
    <main>
      <output-canvas
        ref="output-canvas"
        :aspect-ratio="aspectRatio"
        :image="imageSource"
        :contrast="Number(contrast / 100)"
        :brightness="Number(brightness / 100)"
        :gamma="Number(gamma)"
        :columns="Number(columns)"
        :rows="Number(rows)"
        :keepImageProportions="keepImageProportions"
        :palette="currentPalette"
        :debug="debug"
      />
    </main>
    <sidebar>
      <section>
        <text-link @click="uploadLinkClick">Vælg billede</text-link>

        <!-- {{ aspectRatio }} {{ keepImageProportions }} -->
        <div class="preview" v-if="imageSource">
          <image-preview
            :aspectRatio="aspectRatio"
            :keepImageProportions="keepImageProportions"
            :imageSource="imageSource"
          />
        </div>
      </section>

      <control-group title="Farveskala" :open="false">
        <palette-selector
          :palettes="palettes"
          :selectedPaletteIndex="selectedPaletteIndex"
          @change="paletteChange($event)"
        />
      </control-group>

      <control-group title="Filtre" :open="false" v-if="currentPalette">
        <div class="input">
          <div class="palette-preview">
            <div
              class="swatch"
              v-for="({ rgb }, index) in currentPalette.colors"
              :key="index"
              :style="{ background: cssColor(rgb) }"
            ></div>
          </div>
        </div>
        <div class="input">
          <checkbox
            label="Spejlvend farver"
            :checked="filterFlipped"
            @change="filterFlipped = $event"
          />
        </div>
        <div class="input">
          <div class="label">
            <span>Forskyd</span> <span>{{ paletteOffset }}</span>
          </div>

          <slider
            v-model="paletteOffset"
            min="0"
            :max="currentPalette.colors.length"
            step="1"
          />
        </div>
        <div class="input">
          <text-link size="small" @click="filterRandom"
            >Vilkårlig rækkefølge</text-link
          >
        </div>

        <text-link size="small" @click="filterReset">Nulstil filtre</text-link>
      </control-group>
      <control-group title="Grid">
        <div class="input">
          <div class="label">
            <span>Kolonner</span>
            <span>{{ columns }}</span>
          </div>
          <slider min="1" max="30" v-model="columns" step="1" />
        </div>
        <div class="input">
          <div class="label">
            <span>Rækker</span>
            <span>{{ rows }}</span>
          </div>
          <slider min="1" max="30" v-model="rows" step="1" />
        </div>
      </control-group>

      <control-group title="Format">
        <format-selector
          :formats="formatsArray"
          :selectedFormatIndex="selectedFormatIndex"
          @change="selectedFormatIndex = $event"
        />
        <label>
          <checkbox
            label="Behold billede proportioner"
            :checked="keepImageProportions"
            @change="keepImageProportions = $event"
          />
        </label>
      </control-group>

      <control-group title="Farvejustering">
        <div class="input">
          <div class="label">
            <span>Kontrast</span> <span>{{ contrast }}%</span>
          </div>
          <slider min="0" max="1000" v-model="contrast" step="1" />
        </div>
        <div class="input">
          <div class="label">
            <span>Lysstyrke</span> <span>{{ brightness }}%</span>
          </div>
          <slider min="0" max="200" v-model="brightness" step="1" />
        </div>
      </control-group>

      <section class="export">
        <pill-button @click="exportClick">Eksportér</pill-button>
      </section>
    </sidebar>
  </div>
</template>

<script>
import gsap from "gsap";

import Colors from "../components/Colors";

import PillButton from "../components/PillButton.vue";
import Sidebar from "../components/Sidebar.vue";
import ControlGroup from "../components/ControlGroup.vue";
import TextLink from "../components/TextLink.vue";
import OutputCanvas from "../components/OutputCanvas.vue";
import FormatSelector from "../components/FormatSelector.vue";
import Utils from "../components/Utils.js";
import DragOverFileUpload from "../components/DragOverFileUpload.vue";
import Slider from "../components/Slider.vue";
import PaletteSelector from "../components/PaletteSelector.vue";
import Checkbox from "../components/Checkbox.vue";
import ImagePreview from "../components/ImagePreview.vue";
const { readImage, cssColor } = Utils;

export default {
  name: "GeneratorView",
  components: {
    PillButton,
    Sidebar,
    ControlGroup,
    TextLink,
    OutputCanvas,
    FormatSelector,
    DragOverFileUpload,
    Slider,
    PaletteSelector,
    Checkbox,
    ImagePreview,
  },
  data() {
    return {
      filterFlipped: false,
      currentPalette: null,
      paletteOffset: 0,
      paletteOffsetDifference: 0,
      debug: false,
      showExportMenu: false,
      palettes: Colors,
      selectedPaletteIndex: 0,
      keepImageProportions: true,
      columns: 5,
      rows: 7,
      brightness: 100,
      contrast: 100,
      gamma: 1,
      showDragMessage: false,
      dragMessage: null,
      fileInput: null,
      aspectRatio: 1,
      imageSource: null,
      selectedFormatIndex: 0,
      formatsArray: [
        { width: 16, height: 9, selected: true },
        { width: 4, height: 3, selected: false },
        { width: 1, height: 1, selected: false },
        { width: 2, height: 3, selected: false },
      ],
    };
  },
  methods: {
    cssColor,
    paletteOffsetChange: function () {
      let newColors = [...this.currentPalette.colors];

      if (this.paletteOffsetDifference !== 0) {
        for (let i = 0; i < Math.abs(this.paletteOffsetDifference); i++) {
          if (this.paletteOffsetDifference > 0) {
            newColors.unshift(newColors.pop());
          } else {
            newColors.push(newColors.shift());
          }
        }
        this.currentPalette = { ...this.currentPalette };
        this.currentPalette.colors = newColors;
      }
    },
    paletteChange: function (index) {
      this.selectedPaletteIndex = index;
      this.currentPalette = this.palettes[this.selectedPaletteIndex];
      this.filterReset();
    },
    filterFlip: function () {
      const newColors = this.currentPalette.colors.reverse();
      this.currentPalette = { ...this.currentPalette };
      this.currentPalette.colors = newColors;
    },
    filterRandom: function () {
      const newColors = gsap.utils.shuffle([...this.currentPalette.colors]);
      this.currentPalette = { ...this.currentPalette };
      this.currentPalette.colors = newColors;
    },
    filterReset: function () {
      this.filterFlipped = false;
      this.paletteOffset = 0;
      this.paletteOffsetDifference = 0;
      this.paletteOffsetChange();
      this.$nextTick(() => {
        this.currentPalette = this.originalArray;
      });
    },
    exportAs: function (type) {
      this.$refs["output-canvas"].exportAs(type);
      this.closeExportMenu();
    },
    fileInputChange: async function (event) {
      // const file = this.fileInput.files[0];
      const file = event.target.files[0];

      if (file.type.match("image/*")) {
        this.imageSource = await readImage(file);
      }
      this.showDragMessage = false;
    },
    uploadLinkClick: function () {
      this.fileInput.click();
    },
    closeExportMenu: function (event) {
      this.showExportMenu = false;
      window.removeEventListener("click", this.closeExportMenu);
    },
    exportClick: function (event) {
      event.stopPropagation();
      const { x, y } = event;

      const menuX = x - 50;
      const menuY = y - 10;

      this.showExportMenu = true;

      gsap.set(this.$refs["export-menu"], {
        x: menuX,
        y: menuY,
      });

      window.addEventListener("click", this.closeExportMenu);
    },
    formatChange: function (ratio) {
      this.aspectRatio = ratio;
    },
  },
  watch: {
    filterFlipped: function () {
      this.filterFlip();
    },
    paletteOffset: function (newValue, oldValue) {
      this.paletteOffsetDifference = newValue - oldValue;
      this.paletteOffsetChange();
    },
    selectedFormatIndex: function (newValue) {
      const format = this.formatsArray[this.selectedFormatIndex];
      this.formatChange(format.width / format.height);
    },
    showDragMessage: function (newValue, oldValue) {
      let opacity = newValue ? 1 : 0;
      gsap.to(this.dragMessage, {
        opacity: opacity,
        duration: 0.3,
        ease: "power2.out",
      });
    },
  },
  computed: {
    originalArray: function () {
      return this.palettes[this.selectedPaletteIndex];
    },
  },
  mounted() {
    this.fileInput = document.createElement("input");
    this.fileInput.setAttribute("type", "file");
    this.fileInput.setAttribute("accept", "image/*");
    this.fileInput.addEventListener("change", this.fileInputChange);
    this.dragMessage = this.$refs["drag-message-layout"];
    const dragInput = this.$refs["drag-input"];
    dragInput.addEventListener("change", this.fileInputChange);

    this.paletteChange(this.selectedPaletteIndex);
    this.paletteOffsetChange();
    const dragLayout = this.$refs["drag-message-layout"];
    const dragTitle = this.$refs["drag-title"];

    dragInput.addEventListener("dragover", ({ x, y }) => {
      const mx = x;
      const my = y;

      gsap.to(dragTitle, {
        duration: 1.4,
        x: mx,
        y: my,
        overwrite: true,
        ease: "expo.out",
      });
    });

    window.addEventListener("dragenter", (event) => {
      // if (
      //   event.dataTransfer.items &&
      //   event.dataTransfer.items[0] &&
      //   event.dataTransfer.items[0].type.match("^image/")
      // ) {
      event.preventDefault();
      this.showDragMessage = true;
      // }
    });
    dragInput.addEventListener("dragleave", (event) => {
      event.preventDefault();
      this.showDragMessage = false;
    });

    window.addEventListener("load", () => {
      const item = this.formatsArray.find((item) => item.selected);
      this.formatChange(item.width / item.height);
    });

    this.$refs["export-menu"].addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    if (process.env.NODE_ENV === "development") {
      let testImage = new Image();
      testImage.onload = () => {
        this.imageSource = testImage.src;
      };
      testImage.src = "test.jpeg";
    }
  },
};
</script>

<style lang="scss" scoped>
.generator-view {
  width: 100vw;
  height: 100vh;
  background: $grey-dark;
  display: flex;

  main {
    position: relative;
    width: calc(100vw - #{$sidebar_width});
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drag-message-layout {
    opacity: 0;
    position: fixed;
    z-index: 50;
    width: 100%;
    height: 100%;
    background: rgba($blue, 0.8);
    h1 {
      position: absolute;
      color: $white;
    }
    pointer-events: none;
  }
  .drag-message {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
    }
  }
}

label.radio {
  display: block;
  margin-bottom: s(2);

  input {
    margin-right: s(1);
  }
}

.swatches {
  display: inline-block;
  height: 30px;

  .swatch {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
}

.palette-preview {
  display: flex;
  width: 100%;
  height: 20px;
  margin-bottom: s(1);
  .swatch {
    flex: 1;
    height: 20px;
  }
}
.color-radio {
  display: flex !important;
  height: 30px;
  align-items: center;
}

.export-menu {
  position: absolute;
  bottom: 100%;
  background: $white;
  padding: s(4);
  border-radius: s(2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0 0 s(2) 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      div {
        opacity: 0.6;
        text-decoration: none;

        &:hover {
          opacity: 1;
        }

        .secondary {
          margin-left: s(2);
          font-size: 0.6em;
        }
      }
    }
  }
}
</style>